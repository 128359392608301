import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import * as React from "react";
import StandardModal from "../../modals/Standard";
import FilteredReceiptItem from "./FilteredReceiptItem";
import { Receipt } from "./types";
import { TabbedSection } from "../../../nessie/components/tabs";
import { translate } from "../../../utils/translate";

type FilteredLikesReceiptsModalProps = {
  title: React.ComponentPropsWithoutRef<typeof StandardModal>["title"];
  close: React.ComponentPropsWithoutRef<typeof StandardModal>["onRequestHide"];
  receipts?: Receipt[];
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: React.ComponentPropsWithoutRef<typeof StandardModal>["data-name"];
};

const FilteredLikesReceiptsModal = ({
  receipts,
  close,
  title,
  "data-name": dataName,
}: FilteredLikesReceiptsModalProps): JSX.Element => {
  const sortedReceipts = sortBy(receipts, "time").reverse();
  const groupedReceipts = groupBy(sortedReceipts, "entityType");

  const getTranslatedTabName = (name: string) => {
    switch (name) {
      case "parent":
        return translate({ str: "dojo.common:post_receipts.header.parent" });
      case "teacher":
        return translate({ str: "dojo.common:post_receipts.header.teacher" });
      case "student":
        return translate({ str: "dojo.common:post_receipts.header.student" });
      default:
        return translate({ str: "dojo.common:post_receipts.header.all" });
    }
  };
  const tabs = Object.entries(groupedReceipts).map(([entityType, receipts]) => {
    return {
      id: entityType,
      label: `${getTranslatedTabName(entityType)} (${receipts.length})`,
      content: (
        <div sx={{ paddingTop: "dt_m", paddingLeft: "dt_m", position: "relative" }}>
          {receipts.map((receipt) => {
            return <FilteredReceiptItem key={receipt.entityId} receipt={receipt} />;
          })}
        </div>
      ),
    };
  });

  const allTab = [
    {
      id: "all",
      label: `${getTranslatedTabName("all")} (${sortedReceipts.length})`,
      content: (
        <div sx={{ paddingTop: "dt_m", paddingLeft: "dt_m", position: "relative" }}>
          {sortedReceipts.map((receipt) => {
            return <FilteredReceiptItem key={receipt.entityId} receipt={receipt} />;
          })}
        </div>
      ),
    },
  ];

  return (
    <StandardModal
      small={true}
      title={title}
      loading={!receipts}
      onRequestHide={close}
      contentStyle={styles.modal}
      data-name={dataName}
      headerStyle={{
        border: "none",
      }}
    >
      <TabbedSection tabs={[...allTab, ...tabs]} tabsStyle={{ paddingX: "dt_s" }} />
    </StandardModal>
  );
};

export default FilteredLikesReceiptsModal;

const styles = {
  modal: {
    padding: "0",
  } as const,
  receipt: {
    padding: "0.8rem",
  },
};
