import uniqueId from "lodash/uniqueId";
import LabelFormElement from "../../../components/misc/LabelFormElement";

export type InputGroupProps = {
  groupType: "checkbox" | "radio";
  className?: string;
  ["aria-describedby"]?: string;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  ["data-name"]?: string;
} & ConditionalProps;

// if both are used together, aria-label is ignored
export type ConditionalProps =
  | {
      groupLabel?: never;
      ["aria-label"]: string;
    }
  | {
      groupLabel: string;
      ["aria-label"]?: never;
    };

export const InputGroup = ({
  groupLabel,
  groupType,
  className,
  ["aria-label"]: ariaLabel,
  ["aria-describedby"]: ariaDescribedBy,
  ["data-name"]: dataName,
  children,
}: InputGroupProps & { children: React.ReactNode }) => {
  const labelId = groupLabel ? uniqueId("groupLabel") : undefined;
  const groupId = uniqueId("groupId");
  return (
    <div
      data-name={dataName}
      role={groupType === "radio" ? "radiogroup" : "group"}
      className={className}
      aria-label={ariaLabel}
      aria-labelledby={labelId}
      sx={{ display: "flex", flexDirection: "column", gap: "xs" }}
      id={groupId}
      aria-describedby={ariaDescribedBy}
    >
      {groupLabel && (
        <LabelFormElement
          htmlFor={groupId}
          labelText={groupLabel}
          id={labelId}
          sx={{ "&:first-letter": { textTransform: "capitalize" } }}
        />
      )}
      {children}
    </div>
  );
};
