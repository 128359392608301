import * as React from "react";
import { TranslatedString } from "../../pods/i18n/translate";
import { translate } from "../../utils/translate";
import StandardModal from "./Standard";

type ConfirmModalProps = {
  width?: string;
  title: TranslatedString;
  bodyText?: React.ReactNode;
  children?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  danger?: boolean;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
  confirm: () => void;
  close: () => void;
  spaceAroundButtons?: boolean;
};

const ConfirmModal = ({
  width = "40rem",
  title,
  bodyText,
  children,
  confirmText,
  cancelText,
  danger,
  "data-name": dataName,
  confirm,
  close,
  spaceAroundButtons,
}: ConfirmModalProps): JSX.Element => {
  return (
    <StandardModal
      width={width}
      title={title}
      onRequestHide={close}
      data-name={dataName}
      primaryButton={{
        text: confirmText || translate("dojo.common:actions.ok"),
        props: {
          kind: danger ? "destructive" : undefined,
          "data-name": `${dataName}-ok`,
          onClick: () => {
            confirm();
            close();
          },
        },
      }}
      secondaryButton={{
        text: cancelText || translate("dojo.common:actions.cancel"),
        props: {
          "data-name": `${dataName}-cancel`,
          onClick: close,
        },
      }}
      spaceAroundButtons={spaceAroundButtons}
    >
      {bodyText ?? children}
    </StandardModal>
  );
};

export default ConfirmModal;
