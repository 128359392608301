import * as logClient from "@classdojo/log-client";
import * as React from "react";
import ReactModal from "react-modal";
import UnstyledButton from "../../components/buttons/UnstyledButton";
import { ClassNames, GlobalCSS, CSSInterpolation, ThemeUIStyleObject } from "../../nessie/stylingLib";
import { ModalsContext } from "../../pods/automatedEvents/modalsContext";
import { translate } from "../../utils/translate";
import { CloseIcon } from "../icons";
import Stack from "./evergreenLib/Stack";
import { theme, NessieThemeColors } from "./theme";
import { NessieSpaceSizes } from "./designTokens";
import HeadlineText from "./typography/headlineText";

export const Modal = ({
  "data-name": dataName,
  children,
  isOpen,
  onRequestClose,
  shouldCloseOnOverlayClick = false,
  overlayStyleOverrides = {},
  legacyOpenEvent,
  label,
  "event-metadata": metadata,
  experiments: experiments,
}: React.PropsWithChildren<{
  isOpen: boolean;
  shouldCloseOnOverlayClick?: boolean;
  onRequestClose?: () => void;
  overlayStyleOverrides?: CSSInterpolation;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
  legacyOpenEvent?: string;
  label?: string;
  "event-metadata"?: object;
  experiments?: string[];
}>) => {
  const { addModal, removeModal } = React.useContext(ModalsContext);
  function logEventOpen() {
    if (!dataName) {
      logClient.logMessage("WARN", `${logClient.getSite()}.modal.missingDataName`);
      return;
    }

    if (addModal) addModal(dataName);
    logClient.logEvent({ eventName: `${logClient.getSite()}.modal.${dataName}.open`, automatedEvent: true, metadata });
    if (legacyOpenEvent) logClient.logEvent({ eventName: legacyOpenEvent, metadata, experiments });
  }

  function logEventClose() {
    if (!dataName) return;

    if (removeModal) removeModal(dataName);
    logClient.logEvent({
      eventName: `${logClient.getSite()}.modal.${dataName}.close`,
      automatedEvent: true,
      metadata,
      experiments,
    });
  }

  return (
    <>
      {/* Avoid the body to scroll behind the modal*/}
      <GlobalCSS
        styles={{
          ".ReactModal__Body--open": {
            overflow: "hidden",
          },
        }}
      />
      <Stack value={theme.stackingOrder.OVERLAY}>
        {(zIndex) => (
          <ClassNames>
            {({ css, cx }) => {
              // This overrides styles for the div react-modal uses for the overlay
              const overlayClassName = css({
                backgroundColor: "rgba(66, 62, 93, .9)",
                position: "fixed",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
              });

              // This overrides styles for the div react-modal uses to render the content.
              const contentClassName = css({
                // padding - So when the window is raelly small we still see a bit of the overlay on the sides.
                // For some reason the bottom padding does not work ?
                padding: "22px",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                flexShrink: 0,
                flexGrow: 1,
                ...(shouldCloseOnOverlayClick && {
                  width: "fit-content",
                  margin: "auto",
                  ":focus-visible": {
                    // more subtile accessibility outline, since its much prominently placed  *around* the modal
                    outlineStyle: "dotted",
                    outlineWidth: "1px",
                    outlineColor: "rgb(0, 95, 204)", // browser outline color
                  },
                }),
              });

              return (
                <ReactModal
                  data={{
                    name: dataName || "modal:css:react_modal",
                    "event-metadata": JSON.stringify(metadata),
                    experiments,
                  }}
                  ariaHideApp={false}
                  onAfterOpen={logEventOpen}
                  onAfterClose={logEventClose}
                  onRequestClose={onRequestClose}
                  overlayClassName={cx(overlayClassName, css({ zIndex }), css(overlayStyleOverrides))}
                  className={contentClassName}
                  isOpen={isOpen}
                  shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                  contentLabel={label}
                >
                  {children}
                </ReactModal>
              );
            }}
          </ClassNames>
        )}
      </Stack>
    </>
  );
};

// -----------------------------------
// Modal Content

type ModalContentProps = {
  width?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  padding?: NessieSpaceSizes;
  paddingTop?: NessieSpaceSizes;
  paddingBottom?: NessieSpaceSizes;
  border?: number | string;
  maxHeight?: number | string;
  minHeight?: number | string;
  overflow?: "hidden" | "initial";
};

export const ModalContent = ({
  children,
  width,
  maxWidth,
  minWidth,
  maxHeight = "100%",
  minHeight = "100px",
  padding = "l",
  paddingTop,
  paddingBottom,
  border = "dt_card",
  overflow = "hidden",
}: React.PropsWithChildren<ModalContentProps>) => (
  <div
    sx={{
      // Just setting a min height for easy debugging when working with a modal.
      minHeight,
      maxHeight,
      padding,
      paddingTop,
      paddingBottom,
      border,
      backgroundColor: "dt_background_primary",
      borderRadius: "dt_radius_m",
      // Since this item is going to live inside a flex container the margin will behave as specified for
      // flex items. Check more here: https://stackoverflow.com/questions/32551291/in-css-flexbox-why-are-there-no-justify-items-and-justify-self-properties/33856609#33856609
      margin: "auto",
      width: width ? width : "100%",
      maxWidth: maxWidth ? maxWidth : "500px",
      minWidth: minWidth ? minWidth : undefined,
      overflow,
      position: "relative",
    }}
  >
    {children}
  </div>
);

// --------------------------------------------
// ModalConfirDialog

export const ModalConfirmDialog = ({ children }: { children?: React.ReactNode }) => (
  <ModalContent maxWidth="500px">{children}</ModalContent>
);

// --------------------------------------------
// ModalFooterActions
export const ModalFooterActions = ({ children, sx }: { children?: React.ReactNode; sx?: ThemeUIStyleObject }) => {
  if (React.Children.count(children) > 1) {
    return (
      <div
        sx={{
          ...sx,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          position: "relative",
          flexWrap: "wrap",
        }}
      >
        {React.Children.map(children, (node) => (
          <div sx={{ position: "relative" }}>{node}</div>
        ))}
      </div>
    );
  } else {
    return (
      <div sx={{ ...sx, display: "flex", alignItems: "center", justifyContent: "flex-end", position: "relative" }}>
        {React.Children.map(children, (node) => node)}
      </div>
    );
  }
};

// ---------------------------------------------
// Modal Title
export const ModalTitle = ({
  children,
  as = "h2",
}: {
  children?: React.ReactNode;
  as?: "h2" | "h3" | "h4" | "h5" | "h6";
}): JSX.Element => (
  <HeadlineText as={as} textAlign="center">
    {children}
  </HeadlineText>
);

// -------------------------------------------------
// Modal close button

const CLOSE_POSITION = 22;

type ModalCloseButtonProps = {
  right?: boolean;
  onClick?: () => void;
  closePosition?: number;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name": string;
  color?: NessieThemeColors;
  "aria-label"?: string;
};

export const ModalCloseButton = ({
  onClick,
  right = false,
  closePosition = CLOSE_POSITION,
  "data-name": dataName,
  color,
  "aria-label": ariaLabel,
}: ModalCloseButtonProps) => {
  return (
    <UnstyledButton
      sx={{
        position: "absolute",
        zIndex: 1,
        top: closePosition,
        ...(right ? { right: closePosition, float: "right" } : { left: closePosition }),
      }}
      data-name={dataName}
      aria-label={ariaLabel ?? translate({ str: "dojo.common:actions.close_dialog", fallback: "Close dialog" })}
      onClick={onClick}
    >
      <CloseIcon color={color ? color : "dt_content_secondary"} />
    </UnstyledButton>
  );
};

export const ModalCircledCloseButton = ({
  onClick,
  right = false,
  closePosition = CLOSE_POSITION,
  "data-name": dataName,
  "data-event-metadata": dataEventMetadata,
  whiteBackground,
  circleSize,
  floating = false,
  "aria-label": ariaLabel,
  fixedPosition = false,
}: {
  right?: boolean;
  closePosition?: number;
  onClick?: () => void;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name": string;
  "data-event-metadata"?: string;
  whiteBackground?: boolean;
  circleSize?: NessieSpaceSizes;
  floating?: boolean;
  "aria-label"?: string;
  fixedPosition?: boolean;
}) => (
  <UnstyledButton
    sx={{
      position: fixedPosition ? "fixed" : "absolute",
      zIndex: 1,
      ...(right ? { right: closePosition, float: "right" } : { left: closePosition }),
      top: closePosition,
      padding: "dt_xxs",
    }}
    data-name={dataName}
    data-event-matadata={dataEventMetadata}
    aria-label={ariaLabel ?? translate({ str: "dojo.common:actions.close_dialog", fallback: "Close dialog" })}
    onClick={onClick}
  >
    <CircledCloseIcon whiteBackground={whiteBackground} circleSize={circleSize} floating={floating} />
  </UnstyledButton>
);

// ----------------------------------------
// CircledCloseIcon

type CircledCloseIconProps = {
  whiteBackground?: boolean;
  circleSize?: NessieSpaceSizes;
  floating?: boolean;
};

export const CircledCloseIcon = ({ whiteBackground, circleSize = "dt_m", floating = false }: CircledCloseIconProps) => (
  <div
    sx={{
      boxShadow: floating ? "0px 0px 2px 2px rgba(45, 64, 150, 0.08)" : undefined,
      borderRadius: "dt_radius_round",
    }}
  >
    <div
      sx={{
        backgroundColor: whiteBackground ? "dt_background_primary" : "dt_background_secondary",
        display: "inline-block",
        cursor: "pointer",
        borderRadius: "dt_radius_round",
        padding: circleSize,
        lineHeight: 0,
        position: "relative",
        boxShadow: floating ? "dt_shadow_shadezies" : undefined,
        ":hover": {
          backgroundColor: "dt_background_tertiary",
        },
      }}
    >
      <CloseIcon sx={{ ":hover": { color: "dt_content_tertiary" } }} />
    </div>
  </div>
);
