import { useIconFromBehavior } from "../../utils/behaviorIconHooks";
import { ChevronRightIcon } from "../../nessie/icons";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { translate } from "../../utils/translate";
import BehaviorCircle from "../misc/BehaviorCircle";
import { Image } from "../misc/Image";
import { format, parse } from "@web-monorepo/dates";

export type AwardType = {
  type: "award";
  _id: string;
  weight: number;
  iconNumber: number;
  positive: boolean;
  time: string;
  name: string;
};
export type CommentType = { type: "comment"; _id: string; body: string; time: string };

type PostContentsDailyReportProps = {
  seeMoreLink?: string;
  additionalItemCount: number;
  items: Array<AwardType | CommentType>;
};

const PostContentsDailyReport = ({
  seeMoreLink,
  additionalItemCount,
  items,
}: PostContentsDailyReportProps): JSX.Element => {
  function renderLink() {
    if (!seeMoreLink) {
      return null;
    }

    let seeMoreText;
    if (additionalItemCount > 0) {
      seeMoreText = `${translate("dojo.common:post_content_award.see_more")} (${additionalItemCount})`;
    } else {
      seeMoreText = translate("dojo.common:post_content_award.see_more");
    }

    return (
      <a href={seeMoreLink} sx={seeMoreStyles} data-name="post_contents_daily_report:see_more">
        <div sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {seeMoreText}
          <ChevronRightIcon size="s" color="dt_content_accent" />
        </div>
      </a>
    );
  }

  return (
    <div>
      {items.map((item) => {
        if (item.type === "award") return <Award key={item._id} award={item} />;
        else return <Comment key={item._id} comment={item} />;
      })}
      {renderLink()}
    </div>
  );
};

const Award = ({ award }: { award: AwardType }) => {
  const { weight, iconNumber, positive } = award;
  const behavior = { positive, iconNumber, points: weight };
  const str = format(parse(award.time), "LT");
  const behaviorIconSrc = useIconFromBehavior(behavior);

  return (
    <div sx={styles.wrapper}>
      <div sx={{ display: "flex", alignItems: "center" }}>
        <div>
          <BehaviorCircle behavior={behavior} size="xs" sx={styles.coloredCircle} />
          <img alt="" src={behaviorIconSrc} sx={styles.image} />
        </div>
        <div sx={{ flex: 1, marginLeft: "dt_l", marginRight: "dt_l" }}>{award.name}</div>
        <div>
          <span sx={{ color: "dt_content_tertiary" }}>{str}</span>
        </div>
      </div>
    </div>
  );
};

const Comment = ({ comment }: { comment: CommentType }) => {
  const str = format(parse(comment.time), "LT");
  return (
    <div sx={styles.wrapper}>
      <div sx={{ display: "flex", alignItems: "center" }}>
        <div>
          <Image alt="" sx={styles.image} src="components/story/note-icon.png" />
        </div>
        <div sx={{ flex: 1, marginLeft: "dt_l", marginRight: "dt_l" }}>{comment.body}</div>
        <div>
          <span sx={{ color: "dt_content_tertiary" }}>{str}</span>
        </div>
      </div>
    </div>
  );
};

const styles: Record<string, ThemeUIStyleObject> = {
  wrapper: {
    position: "relative",
    paddingY: "dt_xs",
    paddingX: "dt_s",
  },

  coloredCircle: {
    position: "absolute",
    left: "4rem",
  },

  image: {
    width: "5rem",
    height: "5rem",
    maxHeight: "5rem",
    maxWidth: "5rem",
  },
};
const seeMoreStyles: ThemeUIStyleObject = {
  display: "block",
  padding: "m",
  borderTop: "dt_divider",
  color: "dt_content_accent",
  fontWeight: 600,
  ":hover": {
    color: "dt_content_accent",
    opacity: "0.8",
  },
};

export default PostContentsDailyReport;
