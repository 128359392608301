import { components } from "@classdojo/ts-api-types/api";
import * as React from "react";
import { useCallback, useState } from "react";
import useSideEffectOnMount from "../../hooks/useSideEffectOnMount";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import utilsLogEvent from "../../utils/logEvent";
import { translate } from "../../utils/translate";
import VisibilitySensor from "../../utils/visibilitySensor";
import CommentList from "./CommentList";
import type { Attachment } from "./PostAttachments";
import PostContentsDailyReport from "./PostContentsDailyReport";
import PostContentsTextAndAttachment from "./PostContentsTextAndAttachment";
import PostHeader from "./PostHeader";
import PostReceipts from "./PostReceipts";
import PostToolbar from "./PostToolbar";
import DeleteModal from "./modals/Delete";

import FilteredLikesReceiptsModal from "./modals/FilteredLikesReceipts";
import FilteredReadsReceiptsModal from "./modals/FilteredReadsReceipts";
import ReceiptsModal from "./modals/Receipts";
import { Receipt } from "./modals/types";

type LikeButton = components["schemas"]["RenderedStoryPostResponse"]["likeButton"];
type CommentButton = components["schemas"]["RenderedStoryPostResponse"]["commentButton"];

type Post = {
  _id: string;
  time: string;
  senderId?: string;
  headerText?: string;
  headerSubtext?: string;
  headerAvatarURL?: string;
  canEdit?: boolean;
  canDelete?: boolean;
  channelId?: string;
  readCount?: number;
  commentCount: number;
  likeCount: number;
  read?: boolean;
  tags?: React.ComponentPropsWithoutRef<typeof PostHeader>["postTags"];
  pending?: boolean;
  isCalendarEvent?: boolean;
  senderName?: string;
  likeButton?: LikeButton;
  commentButton?: CommentButton;

  type: string;
  contents: {
    items?: React.ComponentPropsWithoutRef<typeof PostContentsDailyReport>["items"];
    additionalItemCount?: number;

    body?: string;
    attachments?: Attachment[];
    translation?: string | null;
    translationCount?: number;
  };
};

export type StoryPostProps = {
  disabled?: boolean;
  shouldHideDropdownMenu?: boolean;

  context: React.ComponentPropsWithoutRef<typeof PostToolbar>["context"];
  viewerType: React.ComponentPropsWithoutRef<typeof PostToolbar>["viewerType"];
  myId: React.ComponentPropsWithoutRef<typeof CommentList>["myId"];
  myAvatarURL?: React.ComponentPropsWithoutRef<typeof CommentList>["myAvatarURL"];
  post: Post;

  schoolName?: string;

  canComment: boolean;
  canViewComments?: boolean;

  renderedReads?: Receipt[];
  renderedLikes?: Receipt[];
  renderedComments: React.ComponentPropsWithoutRef<typeof CommentList>["renderedComments"];

  like?: (postId: string) => void;
  unlike?: (postId: string) => void;
  read?: (postId: string) => void;
  edit?: (postId: string, body?: string, attachments?: Attachment[]) => Promise<unknown>;
  delete?: (postId: string) => void;
  onRequestDelete?: (postId: string) => void;
  messagePoster?: (postId: string) => void;
  translate?: (postId: string) => void;
  createComment: (postId: string, body: string) => void;
  deleteComment: (postId: string, commentId: string) => void;
  translateComment?: (commentId: string) => void;
  enableComments?: (postId: string) => void;

  isCalendarEvent?: boolean;

  fetchReads?: (postId: string) => void;
  fetchLikes: (postId: string) => void;
  fetchComments: (postId: string) => void;

  amplitudeKey?: string;
  seeMoreLink?: React.ComponentPropsWithoutRef<typeof PostContentsDailyReport>["seeMoreLink"];
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
  hideDownloadLinkOnVideo?: React.ComponentPropsWithoutRef<
    typeof PostContentsTextAndAttachment
  >["hideDownloadLinkOnVideo"];

  showNewLikesModal?: boolean;

  shouldShowCommentsOnLoad?: boolean;
  renderEditModal?: () => React.ReactNode;
  openEditModal?: () => void;
  showingEditModal?: boolean;
  canTranslateComment?: boolean;
};

// eslint-disable-next-line complexity,react-refresh/only-export-components
const StoryPost = ({
  "data-name": dataName = "storyPost",
  disabled,
  shouldHideDropdownMenu,
  post,
  context,
  viewerType,
  schoolName,
  canComment: canCommentProp,
  canViewComments: canViewCommentsProp,
  renderedComments,
  isCalendarEvent = false,
  myId,
  myAvatarURL,
  shouldShowCommentsOnLoad,
  showNewLikesModal = false,
  renderedLikes,
  renderedReads,
  seeMoreLink,
  amplitudeKey,
  hideDownloadLinkOnVideo,
  like: onLike,
  unlike: onUnlike,
  read: onRead,
  edit: onEdit,
  delete: onDelete,
  onRequestDelete,
  messagePoster: onMessagePoster,
  translate: onTranslate,
  createComment: onCreateComment,
  deleteComment: onDeleteComment,
  translateComment,
  enableComments: onEnableComments,
  fetchComments,
  fetchLikes,
  fetchReads,
  openEditModal,
  renderEditModal,
  showingEditModal,
  canTranslateComment,
}: StoryPostProps): JSX.Element => {
  const [showingComments, setShowingComments] = useState(false);
  const [showingReadsModal, setShowingReadsModal] = useState(false);
  const [showingLikesModal, setShowingLikesModal] = useState(false);
  const [showingDeleteModal, setShowingDeleteModal] = useState(false);
  const [visible, setVisible] = useState(false);

  useSideEffectOnMount(() => {
    // If we already know the Post has no comments then we don't wanna trigger the logic of toggling the comments
    // section and 'fetching' them.
    const hasComments = post.commentCount > 0;

    if (shouldShowCommentsOnLoad && hasComments) {
      toggleComments();
    }
    // We only want to show the comments on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const canSeeLikes = Boolean(fetchLikes);
  const canSeeReads = post.type !== "dailyReport" && fetchReads;
  const canLike = onLike && onUnlike;
  const canComment = canCommentProp && fetchComments;
  const canViewComments = canViewCommentsProp && fetchComments;
  const canEdit = post.canEdit && onEdit;
  const canDelete = post.canDelete && onDelete;
  const canTranslate = Boolean(onTranslate);
  const canMessage = post.channelId && onMessagePoster;
  const canEnableComments = Boolean(onEnableComments);

  /*
      We're going to make these all private methods so we don't need to either:
        1) pass the postId down to subcomponents and require them to call
           their methods with it, which is fragile and counterintuitive
        2) bind the methods with the postId on each render, of the list view,
           since this breaks pureRender.
      Each method will call the corresponding function passed in props with
      the id of the post.
    */

  const logEvent = useCallback(
    (str: string) => {
      if (amplitudeKey) {
        utilsLogEvent(`${amplitudeKey}.${str}`);
      }
    },
    [amplitudeKey],
  );

  const like = useCallback(() => {
    if (onLike) {
      logEvent("post.like");
      onLike(post._id);
    }
  }, [logEvent, onLike, post._id]);

  const unlike = useCallback(() => {
    if (onUnlike) {
      logEvent("post.unlike");
      onUnlike(post._id);
    }
  }, [logEvent, onUnlike, post._id]);

  const doTranslate = useCallback(() => {
    logEvent("post.translate");
    onTranslate?.(post._id);
  }, [logEvent, onTranslate, post._id]);

  const deletePost = useCallback(() => {
    logEvent("post.delete");
    onDelete?.(post._id);
  }, [logEvent, onDelete, post._id]);

  const messagePoster = useCallback(() => {
    logEvent("post.message_poster");
    onMessagePoster?.(post._id);
  }, [logEvent, onMessagePoster, post._id]);

  const createComment = useCallback(
    (body: string) => {
      logEvent("post_comments.add");
      onCreateComment(post._id, body);
    },
    [logEvent, onCreateComment, post._id],
  );

  const deleteComment = useCallback(
    (commentId: string) => {
      logEvent("post_comments.delete");
      onDeleteComment(post._id, commentId);
    },
    [logEvent, onDeleteComment, post._id],
  );

  const enableComments = useCallback(() => {
    onEnableComments?.(post._id);
  }, [onEnableComments, post._id]);

  const toggleComments = useCallback(() => {
    if (showingComments) {
      setShowingComments(false);
      logEvent("post_comments.close");
    } else {
      setShowingComments(true);
      fetchComments(post._id);
      logEvent("post_comments.open");
    }
  }, [fetchComments, logEvent, post._id, showingComments]);

  const toggleLikesModal = useCallback(() => {
    if (showingLikesModal) {
      setShowingLikesModal(false);
    } else {
      setShowingLikesModal(true);
      fetchLikes(post._id);
      logEvent("post_likes.open");
    }
  }, [fetchLikes, logEvent, post._id, showingLikesModal]);

  const toggleReadsModal = useCallback(() => {
    if (showingReadsModal) {
      setShowingReadsModal(false);
    } else {
      setShowingReadsModal(true);
      fetchReads?.(post._id);
      logEvent("post_views.open");
    }
  }, [fetchReads, logEvent, post._id, showingReadsModal]);

  const editStoryPost = useCallback(() => {
    // If the story post is a calendar event we don't want to show the edit modal, we are calling edit
    // and let the consumer decide what to do.
    if (isCalendarEvent) {
      onEdit?.(post._id);
    } else {
      if (openEditModal) openEditModal();
    }
  }, [isCalendarEvent, onEdit, post._id, openEditModal]);

  const handleRequestDeleteModal = useCallback(() => {
    // check for any incoming Custom Delete handler
    if (onRequestDelete) {
      return onRequestDelete(post._id);
    }
    // state management to trigger delete, only if LocalDelete
    if (showingDeleteModal) {
      setShowingDeleteModal(false);
    } else {
      setShowingDeleteModal(true);
    }
  }, [showingDeleteModal, onRequestDelete, post._id]);

  const onVisibilityChange: React.ComponentProps<typeof VisibilitySensor>["onChange"] = useCallback(
    (visible) => {
      setVisible(visible);
      if (visible && !post.read && onRead) {
        onRead(post._id);
      }
    },
    [onRead, post._id, post.read],
  );

  const renderModals = () => {
    // local pathways
    if (showingLikesModal && !showNewLikesModal) {
      return (
        <ReceiptsModal
          title={translate("dojo.common:class_story.likes")}
          receipts={renderedLikes}
          close={toggleLikesModal}
          data-name="postLikesModal"
        />
      );
    } else if (showingLikesModal && !!showNewLikesModal) {
      return (
        <FilteredLikesReceiptsModal
          title={translate("dojo.common:class_story.likes")}
          receipts={renderedLikes}
          close={toggleLikesModal}
          data-name="postFilteredLikesModal"
        />
      );
    } else if (showingReadsModal && !showNewLikesModal) {
      return (
        <ReceiptsModal
          title={translate("dojo.common:class_story.views")}
          receipts={renderedReads}
          close={toggleReadsModal}
          data-name="postReadsModal"
        />
      );
    } else if (showingReadsModal && !!showNewLikesModal) {
      return (
        <FilteredReadsReceiptsModal
          title={translate("dojo.common:class_story.views")}
          receipts={renderedReads}
          close={toggleReadsModal}
          data-name="postFilteredReadsModal"
          isSchoolStory={context === "school"}
        />
      );
    } else if (renderEditModal && showingEditModal) {
      return renderEditModal();
    } else if (showingDeleteModal) {
      // showingDeleteModal would only get set, if no Custom delete handler was provided
      return <DeleteModal delete={deletePost} close={handleRequestDeleteModal} data-name="postDeleteModal" />;
    }
  };

  return (
    <VisibilitySensor onChange={onVisibilityChange}>
      <div sx={styles.post} data-name={dataName}>
        <PostHeader
          postTime={post.time}
          posterId={post.senderId}
          avatarURL={post.headerAvatarURL}
          primaryText={post.headerText || ""}
          secondaryText={post.headerSubtext}
          postTags={post.tags}
          pending={post.pending}
        />
        <PostContents
          post={post}
          seeMoreLink={seeMoreLink}
          logEvent={logEvent}
          visible={visible}
          hideDownloadLinkOnVideo={hideDownloadLinkOnVideo}
          translate={canTranslate ? doTranslate : undefined}
          toggleReads={canSeeReads ? toggleReadsModal : undefined}
        />
        <PostReceipts
          readCount={post.readCount}
          likeCount={post.likeCount}
          commentCount={post.commentCount}
          toggleLikes={canSeeLikes ? toggleLikesModal : undefined}
          toggleReads={canSeeReads ? toggleReadsModal : undefined}
          toggleComments={canComment || canViewComments ? toggleComments : undefined}
        />
        {!post.pending && (
          <PostToolbar
            shouldHideDropdownMenu={shouldHideDropdownMenu}
            context={context}
            viewerType={viewerType}
            contactName={context === "school" ? schoolName : post.senderName}
            canComment={canCommentProp}
            likeButton={post.likeButton || "hidden"}
            commentButton={post.commentButton || "hidden"}
            like={canLike ? like : undefined}
            unlike={canLike ? unlike : undefined}
            toggleComments={canComment ? toggleComments : undefined}
            edit={canEdit ? editStoryPost : undefined}
            delete={canDelete ? handleRequestDeleteModal : undefined}
            messagePoster={canMessage ? messagePoster : undefined}
            enableComments={canEnableComments ? enableComments : undefined}
          />
        )}
        {showingComments && (
          <CommentList
            renderedComments={renderedComments}
            myId={myId}
            myAvatarURL={myAvatarURL}
            createComment={createComment}
            translateComment={translateComment}
            deleteComment={deleteComment}
            canComment={canCommentProp}
            canTranslateComment={canTranslateComment}
          />
        )}
        {disabled && <div data-name="postOverlay" sx={styles.disabledOverlay} />}
        {renderModals()}
      </div>
    </VisibilitySensor>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default React.memo(StoryPost);

type PostContentsProps = {
  post: Post;
  visible: boolean;
  logEvent: React.ComponentPropsWithoutRef<typeof PostContentsTextAndAttachment>["logEvent"];
  translate?: React.ComponentPropsWithoutRef<typeof PostContentsTextAndAttachment>["doTranslate"];
  toggleReads: React.ComponentPropsWithoutRef<typeof PostContentsTextAndAttachment>["toggleReads"];
  hideDownloadLinkOnVideo?: React.ComponentPropsWithoutRef<
    typeof PostContentsTextAndAttachment
  >["hideDownloadLinkOnVideo"];
  seeMoreLink?: React.ComponentPropsWithoutRef<typeof PostContentsDailyReport>["seeMoreLink"];
};

// eslint-disable-next-line react-refresh/only-export-components
const PostContents = ({
  post,
  visible,
  hideDownloadLinkOnVideo,
  logEvent,
  seeMoreLink,
  translate,
  toggleReads,
}: PostContentsProps): JSX.Element => {
  return post.type === "dailyReport" ? (
    <PostContentsDailyReport
      items={post.contents.items || []}
      additionalItemCount={post.contents.additionalItemCount || 0}
      seeMoreLink={seeMoreLink}
    />
  ) : (
    <PostContentsTextAndAttachment
      visible={visible}
      body={post.contents?.body}
      attachments={post.contents.attachments || []}
      translation={post.contents.translation}
      translationCount={post.contents.translationCount}
      hideDownloadLinkOnVideo={hideDownloadLinkOnVideo}
      doTranslate={translate}
      toggleReads={toggleReads}
      logEvent={logEvent}
    />
  );
};

const styles: Record<string, ThemeUIStyleObject> = {
  post: {
    position: "relative",
    width: "50rem",
    backgroundColor: "dt_background_primary",
    border: `dt_card`,
    boxShadow: "dt_shadow_shadezies",
    borderRadius: "dt_radius_s",
    marginY: "dt_m",
    fontSize: "1.6rem",
    paddingTop: "dt_m",
  },
  disabledOverlay: {
    backgroundColor: "dt_background_primary",
    opacity: 0.5,
    position: "absolute",
    borderRadius: "dt_radius_s",
    top: -1,
    right: -1,
    bottom: -1,
    left: -1,
    zIndex: 1,
    cursor: "default",
  },
};
